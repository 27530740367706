import React, { useState } from 'react'
import IM_Context from './Im_Context'

const IM_ContextProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(true)
  const [activeIndex, setActiveIndex] = useState(0)
  const [showClickedTile, setShowClickedTile] = useState('')
  const [clickChanged, setClickChanged] = useState(false)
  const [showSpeedometer, setShowSpeedometer] = useState(false)
  return (
    <IM_Context.Provider
      value={{
        isDarkTheme,
        setIsDarkTheme,
        activeIndex,
        setActiveIndex,
        showSpeedometer,
        setShowSpeedometer,
        showClickedTile,
        setShowClickedTile,
        clickChanged,
        setClickChanged,
      }}
    >
      {children}
    </IM_Context.Provider>
  )
}

export default IM_ContextProvider
